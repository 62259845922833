export function splitString(theString) {
    const midIndex = Math.floor(theString.length / 2);
    return [theString.substr(0, midIndex), theString.substr(midIndex)];
}

export function getRandomInt(min, max) {
    // min = Math.ceil(min);
    // max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}

export function getUniqueRandomNumbers(length, min, max, seen = {}) {
    const arr = [];
    while (arr.length < length) {
        const randomInt = getRandomInt(min, max);
        if (!seen[randomInt]) {
            seen[randomInt] = true;
            arr.push(randomInt);
        }
    }
    return arr;
}

export function pickUniqueRandomItems(source, length) {
    return getUniqueRandomNumbers(length, 0, source.length).map(index => source[index]);
}

export function pickUniqueRandomItemsWithMemory(source, length, { used = [], minimumAvailable, persist }) {
    const totalCount = source.length;
    if (totalCount - used.length < minimumAvailable) {
        used = used.slice(minimumAvailable - (totalCount - used.length));
    }
    const usedSet = new Set(used);
    const available = source.map((x, i) => i).filter(i => !usedSet.has(i));
    const picked = pickUniqueRandomItems(available, length);
    used = used.concat(picked);
    persist(used);
    return picked.map(i => source[i]);
}
